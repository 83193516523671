import {
  Holidays,
  Shift,
  SurchargeData,
  SurchargeTimeslot,
} from "../../../redux/apis/types";
import moment, { Moment } from "moment-timezone";
import { timeZoneMoment } from "../../../redux/apis/utils";
import { get, SERVICES } from "../../../redux/apis/global";

export async function getSurchargeData(branchId: number) {
  const resp = await get(`${SERVICES.SURCHARGE}/branch`, branchId);
  if (resp && !resp.error) {
    return resp;
  } else {
    alert("Coulnd't fetch surcharge data");
  }
}

export const calculateSubcontractorSurcharge = (
  record: Shift,
  holidays: Holidays[],
  surchargeData: SurchargeData[]
): {
  surcharge: number;
  branchSurcharge: number;
  currency: string;
  surchargeDetails: SurchargeTimeslot;
} => {
  const { start, end, planning } = record;
  const country = planning?.location?.country || "Unknown";
  const timezone = planning?.location?.timezone || "UTC";
  const currency = planning?.branch_rate?.currency || "EUR";
  const branchSurchargeApplicable = planning?.surcharge_applicable;
  const purchaseCost = record.total_purchase_cost;
  if (!holidays || !purchaseCost)
    return { surcharge: 0, branchSurcharge: 0, currency, surchargeDetails: {} };

  const isAgentSurchargeApplicable = record.agent_shifts.some(
    (agentShift) => agentShift.agent?.surcharge_applicable
  );

  const branchRate = planning?.branch_rate;
  const branchHolidayRate = branchRate?.holiday_rate || 0;

  let subcHolidayRate = 0;
  const subcontractorRate = planning?.branch_rate?.subcontractor_rates?.find(
    (rate: any) => rate.subcontractor_id === record.subcontractor_id
  );

  if (subcontractorRate) {
    subcHolidayRate = subcontractorRate.holiday_rate || 0;
  }

  const subcSurChargeApplicable =
    record.subcontractor?.surcharge_applicable === true ||
    isAgentSurchargeApplicable;

  const realSurcharge = getRealSurcharge(
    surchargeData,
    branchHolidayRate,
    subcHolidayRate
  );

  let surcharge = 0;
  let branchSurcharge = 0;
  const surchargeDetails = calculateHourlyDetails(
    start,
    end,
    planning.id,
    branchSurchargeApplicable,
    holidays,
    timezone,
    timeZoneMoment,
    realSurcharge,
    country,
    record.id,
    subcSurChargeApplicable
  );

  Object.keys(surchargeDetails).map((key) => {
    const surcharges = surchargeDetails[key];

    for (let i = 0; i < surcharges.length; i++) {
      const item = surcharges[i];

      if (item.subcontractorPercentage) {
        surcharge +=
          item.hours *
          ((item.subcontractorPercentage *
            ((subcontractorRate?.rate || 0) / 100)) /
            100);
      }

      branchSurcharge +=
        item.hours *
        ((item.branchPercentage * ((branchRate?.rate || 0) / 100)) / 100);
    }
  });

  return {
    surcharge: parseFloat((surcharge * record.number_of_agents).toFixed(1)),
    branchSurcharge,
    surchargeDetails,
    currency,
  };
};

const isMatchingHoliday = (
  holiday: Holidays,
  time: Moment,
  country: string,
  timezone: string
) =>
  timeZoneMoment(holiday.date, timezone).isSame(time, "day") &&
  country === holiday.country;

export const getHourlySurchargePercentage = (
  branchSurchargeApplicable: boolean | undefined,
  surcharge: SurchargeData,
  currentTime: Moment,
  endTime: Moment,
  holidays: Holidays[] | null,
  country: string,
  timezone: string,
  subcSurchargeApplicable?: boolean
) => {
  const slotStart = currentTime.clone();
  let slotEnd = currentTime.clone().hour(surcharge.end).minute(0).second(0);

  if (surcharge.end === 24) {
    slotEnd = currentTime.clone().endOf("day").minute(59).second(59);
  }

  if (slotEnd.isAfter(endTime)) {
    slotEnd = endTime.clone();
  }

  const currentClone = currentTime.clone();
  const holiday =
    holidays &&
    holidays?.find((holiday) =>
      isMatchingHoliday(holiday, currentClone, country, timezone)
    );

  let period = "";
  let surchargeRate = 0;
  let subcontractorSurchargeRate;

  if (holiday) {
    period = holiday.name;
    surchargeRate = surcharge.bankHoliday;
    subcontractorSurchargeRate =
      surcharge.surchargeApplicable === false
        ? surcharge.subcBankHoliday
        : surcharge.bankHoliday;
  } else {
    const dayOfWeek = currentTime.day();

    if (dayOfWeek === 0) {
      surchargeRate = surcharge.sunday;
      subcontractorSurchargeRate = surcharge.sunday;
      period = "Sunday";
    } else if (dayOfWeek === 6) {
      surchargeRate = surcharge.saturday;
      subcontractorSurchargeRate = surcharge.saturday;
      period = "Saturday";
    } else {
      surchargeRate = surcharge.weekday;
      subcontractorSurchargeRate = surcharge.weekday;
      period = "Weekday";
    }
  }
  period += ` - ${surcharge.time}`;

  const copyStart = slotStart.clone().second(0);
  const hours = slotEnd.diff(copyStart, "hours", true);

  console.log("period : ", period);
  return {
    surchargeRate: branchSurchargeApplicable ? surchargeRate : 0,
    subcontractorSurchargeRate: subcSurchargeApplicable
      ? subcontractorSurchargeRate
      : 0,
    slotEnd,
    slotStart: copyStart,
    period,
    hours,
  };
};

export const getRealSurcharge = (
  surchargeData: SurchargeData[] | undefined,
  branchHolidayPercentage: number,
  subcHolidayPercentage?: number
) => {
  return {
    applicableSurcharge: surchargeData,
    nonApplicableSurcharge: [
      {
        key: "1",
        start: 0,
        end: 24,
        time: "holidays",
        bankHoliday: branchHolidayPercentage,
        subcBankHoliday: subcHolidayPercentage || 0,
        weekday: 0,
        saturday: 0,
        sunday: 0,
        surchargeApplicable: false,
      },
    ],
  };
  // const holidaySurcharge = [,];
  // if (surchargeData) return [...surchargeData, holidaySurcharge];

  // return [holidaySurcharge];
};

export const generateSurchargeTimeslots = (
  branchSurchargeApplicable: boolean | undefined,
  timeSlots: SurchargeTimeslot,
  currentTime: Moment,
  endTime: Moment,
  surchargeTable: SurchargeData[],
  holidays: Holidays[] | null,
  country: string,
  timezone: string,
  planningId: number,
  shiftId?: number,
  subcSurchargeApplicable?: boolean
) => {
  while (currentTime.isBefore(endTime)) {
    for (const surcharge of surchargeTable) {
      const {
        hours,
        slotStart,
        slotEnd,
        surchargeRate,
        subcontractorSurchargeRate,
        period,
      } = getHourlySurchargePercentage(
        branchSurchargeApplicable,
        surcharge,
        currentTime,
        endTime,
        holidays,
        country,
        timezone,
        subcSurchargeApplicable
      );

      if (
        surchargeRate > 0 ||
        (subcontractorSurchargeRate && subcontractorSurchargeRate > 0)
      ) {
        if (!timeSlots[period]) {
          timeSlots[period] = [];
        }

        timeSlots[period].push({
          start: slotStart,
          end: slotEnd,
          hours: hours,
          branchPercentage: surchargeRate,
          subcontractorPercentage: subcontractorSurchargeRate,
          planningId,
          shiftId,
        });
      }

      currentTime = slotEnd.clone().add(1, "second");

      if (currentTime.isSameOrAfter(endTime)) {
        break;
      }
    }
  }

  return timeSlots;
};
export const calculateHourlyDetails = (
  start: string,
  end: string,
  planningId: number,
  branch_surcharge_applicable: boolean | undefined,
  holidays: Holidays[] | null,
  timezone: string,
  timeZoneMoment: (date: string, timezone: string) => moment.Moment,
  surchargeTable: {
    applicableSurcharge: SurchargeData[] | undefined;
    nonApplicableSurcharge: SurchargeData[];
  },
  country: string,
  shiftId?: number,
  subc_surcharge_applicable?: boolean | undefined
): SurchargeTimeslot => {
  const startTime = timeZoneMoment(start, timezone);
  const endTime = timeZoneMoment(end, timezone);
  let timeSlots: SurchargeTimeslot = {};

  let currentTime = startTime.clone();

  if (
    surchargeTable.applicableSurcharge &&
    ((subc_surcharge_applicable && shiftId) || branch_surcharge_applicable)
  ) {
    timeSlots = generateSurchargeTimeslots(
      branch_surcharge_applicable,
      timeSlots,
      currentTime,
      endTime,
      surchargeTable.applicableSurcharge,
      holidays,
      country,
      timezone,
      planningId,
      shiftId,
      subc_surcharge_applicable
    );
  }
  if ((!subc_surcharge_applicable && shiftId) || !branch_surcharge_applicable) {
    timeSlots = generateSurchargeTimeslots(
      branch_surcharge_applicable ? false : true, // in other way , if surcharge is applicable then it should not also applicable for non surcharge calculation
      timeSlots,
      currentTime,
      endTime,
      surchargeTable.nonApplicableSurcharge,
      holidays,
      country,
      timezone,
      planningId,
      shiftId,
      subc_surcharge_applicable ? false : true
    );
  }

  return timeSlots;
};

const fetchHolidays = async () => {
  try {
    const resp = await get(SERVICES.HOLIDAYS);
    if (resp) {
      return resp;
    }
  } catch (error) {
    console.error("Error fetching holidays:", error);
    return [];
  }
};

export const getShiftsWithSurcharge = async (shifts: Shift[]) => {
  const holidays = await fetchHolidays();

  const branchId = shifts[0].planning?.branch_id;
  const surchargeData = await getSurchargeData(branchId);

  if (!holidays || holidays.length === 0) {
    return shifts;
  }
  if (!surchargeData || surchargeData.length === 0) {
    return shifts;
  }

  return shifts.map((item) => {
    const { surcharge, branchSurcharge, surchargeDetails } =
      calculateSubcontractorSurcharge(item, holidays, surchargeData.surcharge);

    const purchaseCost = item.total_purchase_cost;

    const totalSalesCostWithSurcharge = item.planning.invoice_id
      ? item.total_sales_cost_with_surcharge
      : item.total_sales_cost + branchSurcharge;

    const totalPurchaseCostWithSurcharge = item.subcontractor_invoice_id
      ? item.total_purchase_cost_with_surcharge
      : purchaseCost + surcharge;

    const totalMargeWithSurcharge =
      totalSalesCostWithSurcharge - totalPurchaseCostWithSurcharge;
    let margePercentage = 0;

    if (totalSalesCostWithSurcharge > 0) {
      margePercentage =
        (totalMargeWithSurcharge / totalSalesCostWithSurcharge) * 100;
    }

    return {
      ...item,
      total_purchase_cost_with_surcharge: totalPurchaseCostWithSurcharge,
      total_sales_cost_with_surcharge: totalSalesCostWithSurcharge,
      surcharge,
      branchSurcharge:
        item.planning.invoice_id && item.branch_surcharge
          ? item.branch_surcharge
          : branchSurcharge,
      surchargeDetails,
      total_marge: totalMargeWithSurcharge,
      total_marge_percentage: margePercentage,
    };
  });
};
