import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InputController from "../../../form/InputController";
import Button from "../../../components/uielements/button";
import { Request } from "../../../redux/apis/types";
import { SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/planning/actions";
import _ from "lodash";
import TextAreaController from "../../../form/TextAreaController";
import RadioGroupController from "../../../form/RadioGroupController";
type Props = {
  request: Request;
};
const RequestFormInfo = ({ request }: Props) => {
  const dispatch = useDispatch();
  const { planning } = useSelector((state: State) => state.Planning);
  const [loading, setLoading] = useState(false);
  const methods = useForm();
  const { formState, handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    if (request?.po_number) {
      setValue("po_number", request?.po_number);
    }
    setValue("surcharge_applicable", request.surcharge_applicable);
  }, [request, setValue]);
  const onSave = async (data: any) => {
    setLoading(true);
    await update(SERVICES.REQUEST, request?.id, { request: data });
    setLoading(false);
    reset(data);

    let copyPlanning = _.cloneDeep(planning);
    copyPlanning = _.map(copyPlanning, (item) => {
      if (item.request_id === request?.id) {
        return {
          ...item,
          po_number: data.po_number,
          surcharge_applicable: data.surcharge_applicable,
        };
      }
      return item;
    });

    dispatch(actions.setPlanning(copyPlanning));
  };
  return (
    <FormProvider {...methods}>
      <h3 style={{ marginTop: 20, marginBottom: 10 }}>
        Request Information :{" "}
      </h3>
      <section>
        <TextAreaController
          name="po_number"
          label="PO number"
          placeholder="PO number"
        />
      </section>
      <section>
        <RadioGroupController
          label="Surcharge Applicable"
          options={[
            { label: "NO", value: false },
            { label: "YES", value: true },
          ]}
          optionKey="value"
          optionValue="label"
          name={`surcharge_applicable`}
        />
      </section>
      <Button
        onClick={handleSubmit(onSave)}
        disabled={!formState.isDirty || loading}
        loading={loading}
        type="primary"
      >
        Save request
      </Button>
    </FormProvider>
  );
};

export default RequestFormInfo;
