import moment from "moment-timezone";
import {
  Equipment,
  Holidays,
  Planning,
  PlanningRate,
  Shift,
  SurchargeData,
} from "../../../../redux/apis/types";
import _groupBy from "lodash/groupBy";
import _ from "lodash";
import { timeZoneMoment } from "../../../../redux/apis/utils";
import {
  calculateHourlyDetails,
  getRealSurcharge,
  getSurchargeData,
} from "../../invoices/surchargeUtils";

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  // const base64WithoutPrefix = data.substr(
  //   "data:application/pdf;base64,".length
  // );

  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

export const getCombinedPoNumber = (data: Planning[]) => {
  const po_numbers = Array.from(
    new Set(data.map((x) => x.po_number).filter((x) => !!x))
  ).join("-");
  return po_numbers || "";
};

export const generateSubcontractorInvoiceRequestBody = (data: any) => {
  let invoice = {
    subcontractor_id: data.shifts[0].subcontractor_id,
    invoice_number: data.invoice_number,
    invoice_date: moment(data.invoice_date).format("YYYY-MM-DD HH:mm"),
    currency: data.currency,
    vat: data.vat,
    total: data.total_amount,
    status: "generated",
    data: {
      work: data.shifts.map((item: Shift) => ({
        ...item,
        surcharge: parseFloat(item.surcharge + ""),
      })),
      vat: data.vat,
      total: data.total_amount,
      amount: data.amount,
    },
  };

  return invoice;
};

export const hasExtraHours = (planning: Planning[]) => {
  return (
    planning.filter((item) => !!item.extra_hours && item.extra_hours > 0)
      .length === planning.length
  );
};
export const generateInvoiceRequestBody = (
  data: any,
  equipmentState: Equipment[],
  creditInvoice: boolean
) => {
  const MULTIPLIER = creditInvoice ? -1 : 1;
  const equipmentRange = data.request_equipment_range;
  let invoice = _.cloneDeep(data);

  let totalInvoice = 0;

  for (let i = 0; i < invoice.work.length; i++) {
    const planningRates = invoice.work[i].planningRates;
    let total = 0;

    for (let j = 0; j < planningRates.length; j++) {
      const rate = getTotalSinglePlanningRate(planningRates[j]);
      total += rate;
      invoice.work[i].planningRates[j].total = rate * MULTIPLIER;
      invoice.work[i].planningRates[j].amount *= MULTIPLIER;
      if (invoice.work[i].planningRates[j].start instanceof moment) {
        invoice.work[i].planningRates[j].start =
          invoice.work[i].planningRates[j].start.format("DD-MM-YYYY HH:mm");
      } else {
        invoice.work[i].planningRates[j].start = moment(
          invoice.work[i].planningRates[j].start,
          "DD-MM-YYYY HH:mm"
        ).format("DD-MM-YYYY HH:mm");
      }

      if (invoice.work[i].planningRates[j].end instanceof moment) {
        invoice.work[i].planningRates[j].end =
          invoice.work[i].planningRates[j].end.format("DD-MM-YYYY HH:mm");
      } else {
        invoice.work[i].planningRates[j].end = moment(
          invoice.work[i].planningRates[j].end,
          "DD-MM-YYYY HH:mm"
        ).format("DD-MM-YYYY HH:mm");
      }
    }
    invoice.work[i].total = (total * MULTIPLIER).toFixed(2);
    totalInvoice += total;
  }

  let generatedEquipmentList: Array<any> = [];

  for (let i = 0; i < equipmentRange.length; i++) {
    // const totalRangeEquipment = getDateRangeEquipmentRates(equipmentRange[i]);
    const amount = Math.round(
      moment
        .duration(
          moment(equipmentRange[i].end).diff(moment(equipmentRange[i].start))
        )
        .asDays()
    );

    for (let j = 0; j < equipmentRange[i].equipment.length; j++) {
      const equipment = equipmentRange[i].equipment[j];
      generatedEquipmentList.push({
        start: moment(equipmentRange[i].start).format("YYYY-MM-DD"),
        end: moment(equipmentRange[i].end).format("YYYY-MM-DD"),
        amount: amount * MULTIPLIER,
        total: amount * equipment.quantity * equipment.price * MULTIPLIER,
        currency: invoice.currency,
        price: equipment.price * MULTIPLIER,
        description: equipment.description,
        quantity: equipment.quantity,
        equipmentName: equipmentState.find(
          (item) => item.id == equipment.equipment_id
        )?.name,
      });
    }
    totalInvoice += getTotalEquipmentRates(equipmentRange);
    // const equipmentList = equipmentRange[i].equipment.map((item) => ({
    //   ...item,
    //   start:
    // }))
  }

  if (generatedEquipmentList && generatedEquipmentList.length > 0) {
    invoice.equipment = {
      list: generatedEquipmentList,
      total: getTotalEquipmentRates(equipmentRange) * MULTIPLIER,
      currency: invoice.currency,
    };
  }

  // if (!invoice.vat) {
  //   invoice.vat = "N/A";
  // }
  invoice.totalExcl = (totalInvoice * MULTIPLIER).toFixed(2);
  invoice.totalIncl = (
    (totalInvoice +
      (totalInvoice * (invoice.vat !== "N/A" ? invoice.vat : 0)) / 100) *
    MULTIPLIER
  ).toFixed(2);

  invoice.invoice_date = moment(invoice.invoice_date).format(
    "YYYY-MM-DD HH:mm"
  );

  return invoice;
};
export const calculateTotalRate = (invoiceRates: any) =>
  invoiceRates.reduce((accumulator: number, object: any) => {
    return accumulator + object.totalRate;
  }, 0);

export const formatPrice = (
  value: number,
  currency: string,
  creditInvoice: boolean | undefined
) => {
  let price: any = parseFloat((value || 0).toString());
  if (creditInvoice) {
    price = price * -1;
  }

  price = price.toFixed(2);

  if (!currency) return price;

  return `${price.replace(".", ",")} ${currency.toUpperCase()}`;
};

export const getHolidayByDate = (
  holidays: Array<Holidays>,
  planning: Planning
) => {
  return holidays.find(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") ===
        moment(planning.start).format("YYYY-MM-DD") ||
      moment(item.date).format("YYYY-MM-DD") ===
        moment(planning.end).format("YYYY-MM-DD")
  );
};

export const generateInvoice = async (
  planning: Array<Planning>,
  holidays: Array<Holidays>,
  currency: string,
  invoiceRateType: "Default Rate" | "Extra Rate"
) => {
  const planningWithSurcharge = planning.filter(
    (item) => item.surcharge_applicable
  );
  let surchargeData;
  const branch_id = planning[0].branch_id;
  if (planningWithSurcharge.length > 0) {
    surchargeData = await getSurchargeData(branch_id);
  }

  let serviceList = [];

  const grouppedPlanning = _groupBy(
    planning,
    (item: Planning) =>
      item.request_type +
      ":" +
      item.location_id.toString() +
      ":" +
      item.branch_rate_id
  );
  const values = Object.values(grouppedPlanning) as Array<Array<Planning>>;

  for (let i = 0; i < values.length; i++) {
    let nestedValues = _.cloneDeep(values[i]);
    const service = nestedValues[0].request_type;
    let locationName = nestedValues[0].location?.name;

    if (nestedValues.length > 0) {
      const nestedPlanningValues = getPLanningRates(
        nestedValues,
        holidays,
        currency,
        invoiceRateType,
        surchargeData?.surcharge
      );

      if (nestedPlanningValues.planningRates.length > 0) {
        locationName = `${locationName} / ${nestedPlanningValues.planningRates[0].rateName}`;
      }

      serviceList.push({
        service,
        locationName,
        planningRates: nestedPlanningValues.planningRates,
        currency,
        isHoliday: false,
      });
    }
  }

  let surchargeServices: any = [];
  for (let i = 0; i < serviceList.length; i++) {
    let grouppedSurcharge: { [key: string]: any[] } = {};
    const service = serviceList[i];
    const planningRates = service.planningRates.filter(
      (item) => !!item.surcharge
    );
    for (let j = 0; j < planningRates.length; j++) {
      const surcharge = planningRates[j].surcharge;
      if (surcharge) {
        Object.keys(surcharge).map((key) => {
          if (!grouppedSurcharge[key]) {
            grouppedSurcharge[key] = [];
          }
          grouppedSurcharge[key] = grouppedSurcharge[key].concat(
            surcharge[key]
          );
        });
      }
    }

    Object.keys(grouppedSurcharge).map((key) => {
      let surchargePlanningRates = [];
      const surchargeArray = grouppedSurcharge[key];
      for (let j = 0; j < surchargeArray.length; j++) {
        const surchargeItem = surchargeArray[j];
        const planningRate = _.cloneDeep(
          planningRates.find(
            (item) => item.planningId === surchargeArray[j].planningId
          )
        );

        if (planningRate) {
          console.log(
            "(planningRate.price * surchargeItem.branchPercentage) / 100; : ",
            (planningRate.price * surchargeItem.branchPercentage) / 100
          );
          planningRate.surchargePercentage = surchargeItem.branchPercentage;
          planningRate.start = surchargeItem.start;
          planningRate.end = surchargeItem.end;
          planningRate.amount = surchargeItem.hours;
          planningRate.price =
            (planningRate.price * surchargeItem.branchPercentage) / 100;

          surchargePlanningRates.push(planningRate);
        }
      }

      if (surchargePlanningRates.length > 0) {
        surchargeServices.push({
          surchargeKey: key,
          service: service.service,
          locationName:
            service.locationName +
            " / " +
            `${key} (${surchargePlanningRates[0].surchargePercentage}%)`,
          planningRates: surchargePlanningRates,
          currency,
          isHoliday: false,
        });
      }
    });
  }

  serviceList = serviceList.concat(surchargeServices);

  return _.cloneDeep(serviceList.sort(isHoliday));
};

function isHoliday(a: any, b: any) {
  return b.isHoliday - a.isHoliday;
}

export const getTotalSinglePlanningRate = (
  planningSection: any,
  isDay?: boolean
) => {
  // console.log("planningSection.price : ", planningSection.price);
  const start =
    typeof planningSection.start === "string"
      ? moment(planningSection.start, "DD-MM-YYYY HH:mm")
      : planningSection.start;
  const end =
    typeof planningSection.end === "string"
      ? moment(planningSection.end, "DD-MM-YYYY HH:mm")
      : planningSection.end;
  const diff = moment.duration(
    moment(end.toDate()).diff(moment(start.toDate()))
  );
  const amount = isDay ? Math.round(diff.asDays()) : diff.asHours();
  return planningSection.price * planningSection.quantity * amount;
};
export const getTotalPlanningSectionRates = (
  planningSection: Array<any>,
  isDay?: boolean
) => {
  let total = 0;
  for (let i = 0; i < planningSection.length; i++) {
    total += getTotalSinglePlanningRate(planningSection[i], isDay);
  }
  return total;
};

export const getTotalEquipmentRates = (equipmentRange: Array<any>) => {
  if (!equipmentRange) return 0;
  let total = 0;
  for (let i = 0; i < equipmentRange.length; i++) {
    total += getDateRangeEquipmentRates(equipmentRange[i]);
  }
  return total;
};
export const getDateRangeEquipmentRates = (equipmentDateRange: any) => {
  if (!equipmentDateRange) return 0;
  let total = 0;

  const equipmentList = equipmentDateRange.equipment;
  const amount = Math.round(
    moment
      .duration(
        moment(equipmentDateRange.end).diff(moment(equipmentDateRange.start))
      )
      .asDays()
  );

  total += equipmentList.reduce(
    (acc: number, item: any) =>
      acc + item.quantity * parseFloat(item.price) * amount,
    0
  );

  return total;
};

export const getTotalInvoiceRate = (
  invoiceData: Array<any>,
  request_equipment_range: Array<any>,
  vat?: number
) => {
  let totalNet = getTotalEquipmentRates(request_equipment_range as Array<any>);
  let totalVAT = 0;

  if (!invoiceData) return { totalNet, totalVAT };
  for (let i = 0; i < invoiceData.length; i++) {
    totalNet += getTotalPlanningSectionRates(
      invoiceData[i].planningRates,
      false
    );
  }

  if (!!vat) {
    totalVAT = (totalNet * vat) / 100;
  }
  return { totalNet, totalVAT };
};
export const calculatePlanningRates = (
  planning: Array<Planning>,
  branchRates: Array<any>
) => {};
export const getGroupedPlanning = (planning: Array<Planning>) => {
  const grouppedLocation = groupByKey(planning, "location_id");
  const keys = Object.keys(grouppedLocation);
  let structuredInvoice = [];
  for (let i = 0; i < keys.length; i++) {
    structuredInvoice.push({
      location: grouppedLocation[keys[i]][0].location,
      data: groupByKey(grouppedLocation[keys[i]], "request_type"),
    });
  }
  return structuredInvoice;
};

export const groupByKey = (planning: any, key: any) =>
  planning.reduce((group: any, item: any) => {
    const value = item[key];
    group[value] = group[value] ?? [];
    group[value].push(item);
    return group;
  }, {});

export const getPlanningExtraShiftRates = (
  planning: Array<Planning>,
  holidays: Array<Holidays> | null,
  currency: string
): { currency: string; planningRates: PlanningRate[] } => {
  let planningRates = [];

  for (let j = 0; j < planning.length; j++) {
    const planningObj = planning[j] as Planning;
    const shifts = planningObj.shifts.filter((x) => x.extra_rate == 1);
    const branchRate = planning[j].extra_branch_rate;

    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      const rate = branchRate?.rate || 0;
      const holidayRate = branchRate?.holiday_rate || 0;
      const amount = moment
        .duration(moment(shift.end).diff(moment(shift.start)))
        .asHours();

      let price = rate / 100;
      if (holidays) {
        price = (price * holidayRate) / 100;
      }

      const obj: PlanningRate = {
        planningId: planningObj.id,
        start: timeZoneMoment(shift.start, planningObj.location?.timezone),
        end: timeZoneMoment(shift.end, planningObj?.location?.timezone),
        price,
        rateName: branchRate?.name,
        description: !!holidays
          ? getHolidayByDate(holidays, planningObj)?.name
          : "",
        amount,
        quantity: shift.agents.length,
        currency,
        branch_rate_id: branchRate?.id,
      };

      planningRates.push(obj);
    }
  }
  return { currency, planningRates };
};

export const getPLanningRates = (
  planning: Array<Planning>,
  holidays: Array<Holidays> | null,
  currency: string,
  invoiceRateType: "Default Rate" | "Extra Rate",
  surchargeData?: SurchargeData[]
): { currency: string; planningRates: PlanningRate[] } => {
  if (invoiceRateType === "Extra Rate") {
    return getPlanningExtraShiftRates(planning, holidays, currency);
  }

  // console.log("invoiceRateType : ", invoiceRateType);
  let planningRates = [];

  for (let j = 0; j < planning.length; j++) {
    const planningObj = planning[j] as Planning;
    const timezone = planningObj.location?.timezone || "UTC";
    const country = planningObj?.location?.country || "Unknown";
    const start = timeZoneMoment(planningObj.start, timezone);
    const end = timeZoneMoment(planningObj.end, timezone);
    const branchRate = planning[j].branch_rate;

    const rate = branchRate?.rate || 0;
    const holidayRate = branchRate?.holiday_rate || 0;
    const amount = moment
      .duration(moment(planningObj.end).diff(moment(planningObj.start)))
      .asHours();

    const realSurcharge = getRealSurcharge(surchargeData, holidayRate);

    const surcharge = calculateHourlyDetails(
      planningObj.start,
      planningObj.end,
      planningObj.id,
      planningObj.surcharge_applicable,
      holidays,
      timezone,
      timeZoneMoment,
      realSurcharge,
      country
    );

    console.log("surcharge : ", surcharge);

    planningRates.push({
      planningId: planningObj.id,
      start: start,
      end: end,
      price: rate / 100,
      description: !!holidays
        ? getHolidayByDate(holidays, planningObj)?.name
        : "",
      amount,
      rateName: branchRate?.name,
      quantity: planningObj.number_of_agents,
      currency,
      branch_rate_id: branchRate?.id,
      surcharge,
    });
  }

  return { currency, planningRates };
};
